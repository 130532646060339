import React from 'react';
import ReactDOM from 'react-dom';
import MainSection from './components/mainSection.js';
import AboutSection from './components/aboutSection.js';
import SolutionsSection from './components/solutionsSection.js';
import ContactSection from './components/contactSection.js';
import NavBar from "./components/navBar.js"
import './index.scss';

class Index extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      aboutElementRef: React.createRef(),
      solutionsElementRef: React.createRef(),
      contactElementRef: React.createRef(),
    }
  }


  render(){
    return(
      <div className="index-container">
          <MainSection/>
      </div>
    )
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById('root')
);
