import React from 'react';
import './styles/mainSection.scss';
import './styles/section.scss';

import logoName from "../images/tech_easy_logo_name_light.svg"

class MainSection extends React.Component{
  constructor(props){
    super(props)

    this.state = {
      windowWidth: window.innerWidth
    }
  }

  componentDidMount(){
    window.addEventListener('resize',()=>{
      this.setState({
        windowWidth: window.innerWidth
      })
    })
  }

  render(){
    return(
      <section className="main-section-container">
        <div className="main-section-banner-container">
          <img src={logoName} className="main-section-logo" alt="Tech Easy Logo"></img>
        </div>
      </section>
    )
  }
}

export default MainSection
