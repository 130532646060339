import React from "react"
import ReCAPTCHA from "react-google-recaptcha";
import LoadingSpinner from "../components/loadingSpinner"
import './styles/contactForm.scss'

import phoneIcon from "../images/phone_highlight.svg"
import emailIcon from "../images/email_highlight.svg"

require('dotenv').config()

class ContactForm extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      submission: {
        name: {
          first: "",
          last: ""
        },
        jobTitle: "",
        email: "",
        phoneNumber: "",
        company: "",
        message: "",
      },
      isLoading: false
    }

    this.recaptchaRef = React.createRef();

    this.onFirstNameChange = this.onFirstNameChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
    this.onJobTitleChange = this.onJobTitleChange.bind(this);
    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onCompanyChange = this.onCompanyChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
    this.postFormSubmission = this.postFormSubmission.bind(this);
    this.clearSubmission = this.clearSubmission.bind(this);
  }

  onFirstNameChange(event){
    this.setState(prevState => ({
      submission: {
          ...prevState.submission,
          name:{
            ...prevState.submission.name,
            first: event.target.value
          }
      }
    }))
  }
  onLastNameChange(event){
    this.setState(prevState => ({
      submission: {
          ...prevState.submission,
          name:{
            ...prevState.submission.name,
            last: event.target.value
          }
      }
    }))
  }

  onJobTitleChange(event){
    this.setState(prevState => ({
      submission: {
          ...prevState.submission,
          jobTitle: event.target.value
      }
    }))
  }

  onEmailChange(event){
    this.setState(prevState => ({
      submission: {
          ...prevState.submission,
          email: event.target.value
      }
    }))
  }

  onPhoneNumberChange(event){
    this.setState(prevState => ({
      submission: {
          ...prevState.submission,
          phoneNumber: event.target.value
      }
    }))
  }

  onCompanyChange(event){
    this.setState(prevState => ({
      submission: {
          ...prevState.submission,
          company: event.target.value
      }
    }))
  }

  onMessageChange(event){
    this.setState(prevState => ({
      submission: {
          ...prevState.submission,
          message: event.target.value
      }
    }))
  }

  clearSubmission(){
    this.setState({
      submission: {
        name: {
          first: "",
          last: ""
        },
        jobTitle: "",
        email: "",
        phoneNumber: "",
        company: "",
        message: "",
      }
    })
  }

  async onSubmit(event){
    event.preventDefault();

    if(!this.isValidSubmission(this.state.submission)) return

    this.setState({isLoading: true})

    try{
      const token = await this.recaptchaRef.current.executeAsync();

      if(token === null){
        alert("Please verify ReCAPTCHA")
        //console.log("Submission was not posted: Failed to verify ReCAPTCHA")
        return;
      }

      //console.log("Submitting Form",this.state.submission)
      await this.postFormSubmission(this.state.submission)
      this.clearSubmission();
    }catch(e){
      alert("Please verify ReCAPTCHA")
      //console.log("Submission was not posted: Failed to verify ReCAPTCHA because of an error", e)
      this.setState({isLoading: false});
      return;
    }
  }

  isValidSubmission(submission){

    if(!this.isValidData(submission.name.first) || !this.isValidData(submission.name.last)){
      alert("Please Enter a valid name")
      return false
    }

    if(!this.isValidData(submission.email)){
      alert("Please enter a valid email")
      return false
    }

    if(!this.isValidData(submission.phoneNumber)){
      alert("Please enter a valid phone number")
      return false
    }

    if(!this.isValidData(submission.message)){
      alert("Please enter a message")
      return false
    }

    return true;
  }

  isValidData(data){
    return data !== null && data !== undefined && data !== "" && data !== ''
  }

  async postFormSubmission(submission){

    const requestOptions = {
      method: 'POST',
      headers: {
        'X-API-KEY': process.env.REACT_APP_SEND_EMAIL_API_KEY,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(submission)
    };

    try{
      const response = await fetch('https://zcadpztw6i.execute-api.us-east-2.amazonaws.com/techEasy/sendEmail', requestOptions);
      const data = await response.json();

      this.setState({isLoading: false},()=>{
        if(response.status === 200){
          alert("Your message was submitted!")
          //console.log("Contact submission successful: ", data);
        }
        else if(response.status === 500){
          alert("Your message failed to submit")
          //console.log("message submission failure: ", data);
        }
        return data;
      })
    }catch(e){
      this.setState({isLoading: false});
      alert("Your message failed to submit")
      //console.log("message submission failure due to a Fetch error: ",e);
    }
  }

  render(){
    return (
      <div className="contact-form-container">
        <form className="contact-form" onSubmit={this.onSubmit}>
          <div className="contact-form-row">

            <div className="contact-form-element">
              <label>First Name*</label>
              <input type="text" name="name" onChange={this.onFirstNameChange} value={this.state.submission.name.first}/>
            </div>

            <div className="contact-form-element">
              <label>Last Name*</label>
              <input type="text" name="name" onChange={this.onLastNameChange} value={this.state.submission.name.last}/>
            </div>
          </div>

          <div className="contact-form-row">
            <div className="contact-form-element">
              <label>Job Title</label>
              <input type="text" name="job title" onChange={this.onJobTitleChange} value={this.state.submission.jobTitle}/>
            </div>

            <div className="contact-form-element">
              <label>Email*</label>
              <input type="text" name="email" onChange={this.onEmailChange} value={this.state.submission.email}/>
            </div>

          </div>

          <div className="contact-form-row">
            <div className="contact-form-element">
              <label>Phone*</label>
              <input type="text" name="phone number" onChange={this.onPhoneNumberChange} value={this.state.submission.phoneNumber}/>
            </div>

            <div className="contact-form-element">
              <label>Company</label>
              <input type="text" name="email" onChange={this.onCompanyChange} value={this.state.submission.company}/>
            </div>

          </div>

          <div className="contact-form-row">

            <div className="contact-form-element">
              <label>Message</label>
              <textarea className="contact-form-textArea" name="message" rows={10} onChange={this.onMessageChange} value={this.state.submission.message}/>
            </div>

          </div>

          <div className="contact-form-row">
            <div className="contact-form-element">
              <button className="contact-form-submit" type="submit">Submit</button>
            </div>
          </div>
        </form>

        <div className="contact-form-alternative">
          <h2>Alternatively...</h2>
          <p>You can contact us through the following methods</p>
          <p>We look forward to speaking with you</p>

          <div>
            <div className="contact-form-method">
              <img className="contact-form-icon" src={emailIcon} alt="Email Icon"></img>
              <p>enquries@techeasy.com.au</p>
            </div>
            <div className="contact-form-method">
              <img className="contact-form-icon" src={phoneIcon} alt="Phone Icon"></img>
              <p>0452 365 332</p>
            </div>
          </div>
        </div>

        <ReCAPTCHA
          ref={this.recaptchaRef}
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE}
          size="invisible"
        />
        {
          this.state.isLoading && (<LoadingSpinner/>)
        }
    </div>
    );
  }
}

export default ContactForm
