import React from 'react';
import './styles/solutionsSection.scss';
import './styles/section.scss'
class SolutionsSection extends React.Component{

  render(){
    return(
      <section ref={this.props.innerRef}  className="solutions-section-container">
        <header>
          <h1>Solutions</h1>
        </header>

        <main className="solutions-container">
          <div id="one" className="solution">
            <div className="solution-shutter"></div>
            <div className="solution-content">
              <header>
                <h2>Support in creating a clear business case for a change of system</h2>
                <h2 className="dots">...</h2>
              </header>
              <div className="solution-text">
                <p className="tag">^</p>
                <p className="text">This involves creating a full RFP (request for proposal) and score potential system vendors to make sure the decision is clear and mapped out for our clients. This includes full documentation, feedback and supplier demonstrations.</p>
              </div>
            </div>
          </div>

          <div id="two" className="solution">
            <div className="solution-shutter"></div>
            <div className="solution-content">
            <header>
              <h2>Review current ‘As-is’ systems</h2>
              <h2 className="dots">...</h2>
            </header>
            <div className="solution-text">
                <p className="tag">^</p>
                <p className="text">We review the current state of systems and processes to gain understanding of key I.T. personnel, processes, data and technology that is currently used. This is completed with the support of group and individual department workshops to collectively combine all department feedback.</p>
                </div>
            </div>
          </div>

          <div id="three" className="solution">
            <div className="solution-shutter"></div>
            <div className="solution-content">
              <header>
                <h2>Build I.T. roadmap for future growth</h2>
                <h2 className="dots">...</h2>
              </header>
              <div className="solution-text">
                <p className="tag">^</p>
                <p className="text">Once the ‘As-is’ system review is complete, we will investigate and document a workable roadmap for the business and highlight key milestones. We will provide full support to make this achievable.</p>
              </div>
            </div>
          </div>

          <div id="four" className="solution">
            <div className="solution-shutter"></div>
            <div className="solution-content">
              <header>
                <h2>Managed I.T. support services</h2>
                <h2 className="dots">...</h2>
              </header>
              <div className="solution-text">
                <p className="tag">^</p>
                <p className="text">Whether it is network or desktop support, email hosting, or the latest trends in Cloud Computing, as your complete I.T. support service we have got it covered.</p>
              </div>
            </div>
          </div>

          <div id="five" className="solution">
            <div className="solution-shutter"></div>
            <div className="solution-content">
              <header>
                <h2>Network support</h2>
                <h2 className="dots">...</h2>
              </header>
              <div className="solution-text">
                <p className="tag">^</p>
                <p className="text">We provide a complete and accurate picture of your whole network and the security behind it, so you have a better idea of how safe you are from a variety of threats.</p>
              </div>
            </div>
          </div>
        </main>

      </section>
    )
  }
}

export default React.forwardRef((props, ref) => <SolutionsSection
  innerRef={ref} {...props}
/>);