import React from 'react';
import ContactForm from './contactForm';
import './styles/contactSection.scss';
import './styles/section.scss';

class ContactSection extends React.Component{

  render(){
    return(
      <section ref={this.props.innerRef}  className="contact-section-container">
        <header>
          <h1>Contact</h1>
        </header>
        <ContactForm/>
      </section>
    )
  }
}

export default React.forwardRef((props, ref) => <ContactSection
  innerRef={ref} {...props}
/>);