import React from 'react';
import './styles/aboutSection.scss';
import './styles/section.scss';

class AboutSection extends React.Component{

  render(){
    return(
      <section ref={this.props.innerRef} className="about-section-container">
        <header>
          <h1>About</h1>
        </header>
        <p>Using clear communication, we assist our clients to be able to make key I.T. business decisions. We strive to provide a clear vision in terms of technology innovation. At Tech Easy we believe in collaborating with key stakeholders who understand their goals and objectives to get the right outcome.</p>
      </section>
    )
  }
}

export default React.forwardRef((props, ref) => <AboutSection
  innerRef={ref} {...props}
/>);