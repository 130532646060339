import React from 'react';
import './styles/navBar.scss';
import logo from "../images/tech_easy_logo.svg"
import menuIcon from "../images/menu_icon.svg"
import menuIconHighlight from "../images/menu_icon_highlight.svg"

class NavBar extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      windowWidth: window.innerWidth,
      showMenu: false,
    }

    this.listeners = [];

    this.navBarRef = React.createRef();
    this.buttonContainerRef = React.createRef();
    this.navMenuButton = React.createRef();

    this.scrollTo = this.scrollTo.bind(this);
    this.showMenu = this.showMenu.bind(this);
  }

  componentDidMount(){
    this.listeners.push(window.addEventListener('resize',()=>{
      this.setState({
        windowWidth: window.innerWidth
      })
    }))

    this.listeners.push(window.addEventListener('click',(event)=>{
      if(this.navMenuButton.current === null || this.navMenuButton.current === undefined) return

      if(event.target !== this.navMenuButton.current && this.navMenuButton.current.className === "navBar-menu-button highlight"){
        this.showMenu(false)
      }
    }))

    this.listeners.push(window.addEventListener('scroll',()=>{
      if(this.navMenuButton.current === null || this.navMenuButton.current === undefined) return

      if(this.navMenuButton.current.className === "navBar-menu-button highlight"){
        this.showMenu(false)
      }
      this.setState({scrollY:window.scrollY})
    }))
  }

  componentWillUnmount(){
    this.setState({menuIcon: menuIcon})
  }

  scrollTo(elementRef,options){
    //console.log(elementRef.current);
    if(this.navMenuButton.current.className === "navBar-menu-button highlight") this.showMenu(false)

    if(elementRef.current === null || elementRef.current === undefined){
      //console.log("Scroll element is null and undefined")
      return
    }

    //console.log("Scrolling to: ",elementRef.current);

    if(options === null || options === undefined){
      elementRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
    else{
      elementRef.current.scrollIntoView(options);
    }

  }

  showMenu(show){
    this.setState({showMenu: show},()=>{
      //show the menu
      if(this.state.showMenu){
        this.buttonContainerRef.current.style.display = "flex"

        this.navMenuButton.current.className = "navBar-menu-button highlight"
        this.navMenuButton.current.src = menuIconHighlight;
        this.buttonContainerRef.current.className = "navBar-button-container highlight"
      }
      //close the menu
      else{
        this.buttonContainerRef.current.style.display = "none"

        this.navMenuButton.current.className = "navBar-menu-button"
        this.navMenuButton.current.src = menuIcon;
        this.buttonContainerRef.current.className = "navBar-button-container"
      }
    })
  }

  render(){
    return(
      <div className="navBar-container">
        {
          this.state.windowWidth > 500 &&
          <div ref={this.navBarRef} className="navBar-content">
            <img ref={this.navMenuButton} className="navBar-logo" src={logo} alt="Tech Easy Logo" />

            <div className="navBar-button-container">

              <button onClick={()=>{this.scrollTo(this.props.aboutElement)}}><h2>About</h2></button>
              <button onClick={()=>{this.scrollTo(this.props.solutionsElement)}}><h2>Solutions</h2></button>
              <button onClick={()=>{this.scrollTo(this.props.contactElement)}}><h2>Contact</h2></button>
            </div>
          </div>
        }
        {
          this.state.windowWidth <= 500 &&
          <div ref={this.navBarRef} className="navBar-content">
            <img className="navBar-logo" src={logo} alt="Tech Easy Logo" />
            <div className="navBar-menu">

              <img ref={this.navMenuButton} className={`navBar-menu-button`} src={menuIcon} alt="NavBar menu icon" onClick={()=>{this.showMenu(!this.state.showMenu)}}/>

              <div ref={this.buttonContainerRef} className="navBar-button-container">
                <button onClick={()=>{this.scrollTo(this.props.aboutElement)}}><h2>About</h2></button>
                <button onClick={()=>{this.scrollTo(this.props.solutionsElement)}}><h2>Solutions</h2></button>
                <button onClick={()=>{this.scrollTo(this.props.contactElement)}}><h2>Contact</h2></button>
              </div>
            </div>
          </div>
        }

        {
          this.state.scrollY > 350 &&
          <div className="navBar-helper">
            <div className="navBar-helper-up"><h2>^</h2></div>
            <div className="navBar-helper-buttons">
              <button onClick={()=>{this.scrollTo(this.navBarRef,{
                behavior: "smooth",
                block: "end"
              })}}><h2>Top</h2></button>
              <button onClick={()=>{this.scrollTo(this.props.aboutElement)}}><h2>About</h2></button>
              <button onClick={()=>{this.scrollTo(this.props.solutionsElement)}}><h2>Solutions</h2></button>
              <button onClick={()=>{this.scrollTo(this.props.contactElement)}}><h2>Contact</h2></button>
            </div>
          </div>
        }
        </div>

    )
  }
}

export default NavBar